<template>
  <b-modal
    id="renting-form"
    :visible="shallShowRentingFormModal"
    :title="`${$t('Form')} ${$t('TitleBooking.Renting')}`"
    footer-class="d-flex justify-content-between"
    size="xl"
    centered
    no-close-on-backdrop
    @change="(val) => $emit('update:shall-show-renting-form-modal', val)"
  >
    <!-- Modal Header -->
    <template #modal-header>
      <h5 class="modal-title">
        {{ `${$t(typeRentingForm)} ${$t('TitleBooking.Renting')}` }}
      </h5>
      <div class="modal-actions">
        <feather-icon
          icon="MinusIcon"
          class="cursor-pointer"
          @click="(busy === false) ? $emit('update:shall-show-renting-form-modal', false) : false"
        />
        <feather-icon
          icon="XIcon"
          class="ml-1 cursor-pointer"
          @click="(busy === false) ? onDiscard() : false"
        />
      </div>
    </template>

    <!-- Modal Footer -->
    <template #modal-footer>
      <!-- Footer: Left Content -->
      <div>
        <b-button
          v-if="(typeRentingForm !== 'View')"
          ref="submit"
          variant="primary"
          right
          :disabled="busy"
          @click="onSubmit"
        >
          {{ (typeRentingForm === 'Add' || typeRentingForm === 'Edit') ? $t('Save Data') : $t('Delete Data') }}
        </b-button>
      </div>

      <!-- Footer: Right Content -->
      <div>
        <!-- Discard -->
        <feather-icon
          icon="XCircleIcon"
          size="17"
          class="ml-75 cursor-pointer"
          @click="(busy === false) ? onDiscard() : false"
        />
      </div>
    </template>

    <!-- Modal: Body -->
    <validation-observer
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >
      <div>
        <b-form
          ref="refForm"
          class="position-relative"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <div>
            <b-card style="margin-bottom: 0.6rem;">
              <div>
                <b-row>
                  <b-col
                    cols="12"
                    md="6"
                  >
                    <b-row>
                      <!-- Field: RentingUser -->
                      <b-col
                        cols="12"
                        md="12"
                      >
                        <b-form-group
                          v-if="(typeRentingForm === 'Add' || typeRentingForm === 'Edit')"
                          :label="$t('FormRenting.RentingUser')"
                          label-for="renting-renting-user"
                        >
                          <validation-provider
                            #default="validationContext"
                            :name="$t('FormRenting.RentingUser')"
                            rules="required"
                          >
                            <b-form-input
                              v-model="rentingData.renting_user.id"
                              style="display: none;"
                              :state="getValidationState(validationContext)"
                            />
                            <b-input-group
                              class="m-0"
                              @click.prevent="selectRentingUser"
                            >
                              <b-input-group-prepend
                                is-text
                                style="cursor: pointer;"
                              >
                                <feather-icon
                                  v-if="rentingData.line_user.picture_url === '' || rentingData.line_user.picture_url === undefined"
                                  icon="PlusIcon"
                                  size="12"
                                />
                                <b-avatar
                                  v-else
                                  :src="rentingData.line_user.picture_url"
                                  size="24"
                                />
                              </b-input-group-prepend>
                              <b-form-input
                                id="renting-renting-user"
                                v-model="rentingData.renting_user_select"
                                :readonly="true"
                                style="background: #f8f8f8 !important; cursor: pointer;"
                                @click="selectRentingUser"
                              />
                            </b-input-group>
                            <small class="text-danger">{{ validationContext.errors[0] }}</small>
                          </validation-provider>

                        </b-form-group>

                        <b-form-group
                          v-else
                          :label="$t('FormRenting.RentingUser')"
                          label-for="renting-renting-user"
                        >
                          <span class="form-info-box">{{ rentingData.renting_user.name }}</span>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row>
                      <!-- Field: Product -->
                      <b-col
                        cols="12"
                        md="12"
                      >
                        <b-form-group
                          v-if="(typeRentingForm === 'Add' || typeRentingForm === 'Edit')"
                          :label="$t('FormProduct.Product')"
                          label-for="renting-product"
                        >
                          <validation-provider
                            #default="validationContext"
                            :name="$t('FormProduct.Product')"
                            rules="required"
                          >
                            <b-form-input
                              v-model="rentingData.product.id"
                              style="display: none;"
                              :state="getValidationState(validationContext)"
                            />
                            <b-input-group
                              class="m-0"
                              @click.prevent="selectProduct"
                            >
                              <b-input-group-prepend
                                is-text
                                style="cursor: pointer;"
                              >
                                <feather-icon
                                  icon="PlusIcon"
                                  size="12"
                                />
                              </b-input-group-prepend>
                              <b-form-input
                                id="renting-product"
                                v-model="rentingData.product.code"
                                :readonly="true"
                                style="background: #f8f8f8 !important; cursor: pointer;"
                                @click="selectProduct"
                              />
                            </b-input-group>
                            <small class="text-danger">{{ validationContext.errors[0] }}</small>
                          </validation-provider>

                        </b-form-group>

                        <b-form-group
                          v-else
                          :label="$t('FormProduct.Product')"
                          label-for="renting-product"
                        >
                          <span class="form-info-box">{{ rentingData.product.code }}</span>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row>
                      <!-- Field: Name -->
                      <b-col
                        cols="12"
                        md="12"
                      >
                        <b-form-group
                          v-if="(typeRentingForm === 'Add' || typeRentingForm === 'Edit')"
                          :label="$t('FormProduct.ProductName')"
                          label-for="renting-product-name"
                        >
                          <span
                            class="form-info-box"
                            style="min-height: 38px;"
                          >{{ rentingData.name }}</span>
                        </b-form-group>

                        <b-form-group
                          v-else
                          :label="$t('FormProduct.ProductName')"
                          label-for="renting-product-name"
                        >
                          <span class="form-info-box">{{ rentingData.name }}</span>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row>
                      <!-- Field: Product Type -->
                      <b-col
                        cols="12"
                        md="4"
                      >
                        <b-form-group
                          v-if="(typeRentingForm === 'Add' || typeRentingForm === 'Edit')"
                          :label="$t('FormProduct.ProductType')"
                          label-for="renting-product-product-type"
                        >
                          <span
                            class="form-info-box"
                            style="min-height: 38px;"
                          >{{ rentingData.product_type.name }}</span>
                        </b-form-group>

                        <b-form-group
                          v-else
                          :label="$t('FormProduct.ProductType')"
                          label-for="renting-product-product-type"
                        >
                          <span class="form-info-box">{{ rentingData.product_type.name }}</span>
                        </b-form-group>
                      </b-col>
                      <!-- Field: Category -->
                      <b-col
                        cols="12"
                        md="8"
                      >
                        <b-form-group
                          v-if="(typeRentingForm === 'Add' || typeRentingForm === 'Edit')"
                          :label="$t('FormProduct.Category')"
                          label-for="renting-product-category-name"
                        >
                          <span
                            class="form-info-box"
                            style="min-height: 38px;"
                          >{{ rentingData.category_name }}</span>
                        </b-form-group>

                        <b-form-group
                          v-else
                          :label="$t('FormProduct.Category')"
                          label-for="renting-product-category-name"
                        >
                          <span class="form-info-box">{{ rentingData.category_name }}</span>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row>
                      <!-- Field: Department -->
                      <b-col
                        cols="12"
                        md="12"
                      >
                        <b-form-group
                          v-if="(typeRentingForm === 'Add' || typeRentingForm === 'Edit')"
                          :label="$t('FormProduct.Department')"
                          label-for="renting-category-name"
                        >
                          <span
                            class="form-info-box"
                            style="min-height: 38px;"
                          >{{ rentingData.department_name }}</span>
                        </b-form-group>

                        <b-form-group
                          v-else
                          :label="$t('FormProduct.Department')"
                          label-for="renting-category-name"
                        >
                          <span class="form-info-box">{{ rentingData.department_name }}</span>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col cols="12">
                        <b-card
                          v-if="rentingData.product_id > 0"
                          border-variant="info"
                        >
                          <b-row>
                            <!-- Field: Total Day -->
                            <b-col
                              cols="12"
                              md="4"
                            >
                              <b-form-group
                                v-if="(typeRentingForm === 'Add' || typeRentingForm === 'Edit')"
                                :label="$t('PageRenting.Title.TotalDay')"
                                label-for="renting-total-day"
                              >
                                <span
                                  class="form-info-box"
                                  style="min-height: 38px;"
                                >{{ summaryTotalDay }}</span>
                              </b-form-group>

                              <b-form-group
                                v-else
                                :label="$t('PageRenting.Title.TotalDay')"
                                label-for="renting-total-day"
                              >
                                <span class="form-info-box">{{ summaryTotalDay }}</span>
                              </b-form-group>
                            </b-col>
                            <!-- Field: Total Time -->
                            <b-col
                              cols="12"
                              md="4"
                            >
                              <b-form-group
                                v-if="(typeRentingForm === 'Add' || typeRentingForm === 'Edit')"
                                :label="$t('PageRenting.Title.TotalTime')"
                                label-for="renting-total-time"
                              >
                                <span
                                  class="form-info-box"
                                  style="min-height: 38px;"
                                >{{ summaryTotalTime }}</span>
                              </b-form-group>

                              <b-form-group
                                v-else
                                :label="$t('PageRenting.Title.TotalTime')"
                                label-for="renting-total-time"
                              >
                                <span class="form-info-box">{{ summaryTotalTime }}</span>
                              </b-form-group>
                            </b-col>
                            <!-- Field: Total Price -->
                            <b-col
                              cols="12"
                              md="4"
                            >
                              <b-form-group
                                v-if="(typeRentingForm === 'Add' || typeRentingForm === 'Edit')"
                                :label="$t('PageRenting.Title.TotalPrice')"
                                label-for="renting-total-price"
                              >
                                <span
                                  class="form-info-box"
                                  style="min-height: 38px;"
                                >{{ summaryTotalPrice }}</span>
                              </b-form-group>

                              <b-form-group
                                v-else
                                :label="$t('PageRenting.Title.TotalPrice')"
                                label-for="renting-total-price"
                              >
                                <span class="form-info-box">{{ summaryTotalPrice }}</span>
                              </b-form-group>
                            </b-col>
                          </b-row>
                        </b-card>
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col
                    cols="12"
                    md="6"
                  >
                    <b-row>
                      <!-- Field: Fee Type -->
                      <b-col
                        v-if="rentingData.product_id > 0"
                        cols="12"
                        md="4"
                      >
                        <b-form-group
                          v-if="(typeRentingForm === 'Add' || typeRentingForm === 'Edit')"
                          :label="$t('FormRenting.FeeType')"
                          label-for="renting-fee-type"
                        >
                          <validation-provider
                            #default="validationContext"
                            :name="$t('FormRenting.FeeType')"
                            rules="required"
                          >
                            <v-select
                              v-model="rentingData.fee_type"
                              :options="rentingData.fee_type_options"
                              :reduce="val => val.value"
                              :clearable="false"
                              input-id="product-fee-type"
                              :state="getValidationState(validationContext)"
                            />
                            <small class="text-danger">{{ validationContext.errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>

                        <b-form-group
                          v-else
                          :label="$t('FormRenting.FeeType')"
                          label-for="renting-fee-type"
                        >
                          <span class="form-info-box">{{ rentingData.fee_type }}</span>
                        </b-form-group>
                      </b-col>
                      <!-- Field: Calendar -->
                      <b-col
                        v-if="rentingData.product_id > 0"
                        cols="12"
                        md="8"
                      >
                        <b-form-group
                          v-if="(typeRentingForm === 'Add' || typeRentingForm === 'Edit')"
                          :label="$t('FormRenting.RentingDate')"
                          label-for="renting-renting-date"
                        >
                          <validation-provider
                            #default="validationContext"
                            :name="$t('FormRenting.RentingDate')"
                            rules="required"
                          >
                            <b-input-group
                              class="m-0"
                              @click.prevent="selectRentingDate"
                            >
                              <b-input-group-prepend
                                is-text
                                style="cursor: pointer;"
                              >
                                <feather-icon
                                  icon="CalendarIcon"
                                  size="12"
                                />
                              </b-input-group-prepend>
                              <b-form-input
                                id="renting-renting-date"
                                v-model="rentingData.renting_startend_date"
                                :readonly="true"
                                style="background: #f8f8f8 !important; cursor: pointer;"
                                @click="selectRentingDate"
                              />
                            </b-input-group>
                            <van-calendar
                              v-model="showRentingDate"
                              :type="typeCalendar"
                              :default-date="defaultDateCalendar"
                              :style="{ height: '95%' }"
                              :title="$t('FormRenting.RentingDate')"
                              :poppable="true"
                              :show-title="true"
                              :show-subtitle="true"
                              :show-confirm="showButtonConfirmCalendar"
                              :readonly="false"
                              row-height="64"
                              :min-date="minDateCalendar"
                              :max-date="maxDateCalendar"
                              :color="iconColor('primary')"
                              :formatter="formatterCalendar"
                              confirm-text="OK"
                              @select="onSelectCalendar"
                              @confirm="onConfirmCalendar"
                            >
                              <!-- Calendar :: Top Info -->
                              <template #top-info="Day">
                                <div
                                  v-if="topInfoDayFound(Day)"
                                >
                                  <feather-icon
                                    icon="BookmarkIcon"
                                    color="black"
                                    fill="black"
                                  />
                                </div>
                              </template>
                              <!-- Calendar :: Bottom Info -->
                              <template #bottom-info="Day">
                                <div
                                  v-if="bottomInfoDayFound(Day)"
                                >
                                  <div
                                    v-if="bottomInfoDayObj(Day).type === 'text'"
                                    :style="`color: ${bottomInfoDayObj(Day).color}`"
                                  >
                                    {{ bottomInfoDayObj(Day).text }}
                                  </div>
                                  <div v-else-if="bottomInfoDayObj(Day).type === 'start'">
                                    <b-alert
                                      show
                                      :variant="bottomInfoDayObj(Day).variant"
                                      style="margin-bottom: 0 !important; border-radius: 0 !important; width: 100%; height: 14px;"
                                    >
                                      {{ $t('Start') }}
                                    </b-alert>
                                  </div>
                                  <div v-else-if="bottomInfoDayObj(Day).type === 'middle'">
                                    <b-alert
                                      show
                                      :variant="bottomInfoDayObj(Day).variant"
                                      style="margin-bottom: 0 !important; border-radius: 0 !important; width: 100%; height: 14px;"
                                    />
                                  </div>
                                  <div v-else-if="bottomInfoDayObj(Day).type === 'end'">
                                    <b-alert
                                      show
                                      :variant="bottomInfoDayObj(Day).variant"
                                      style="margin-bottom: 0 !important; border-radius: 0 !important; width: 100%; height: 14px;"
                                    >
                                      {{ $t('End') }}
                                    </b-alert>
                                  </div>
                                  <div v-else-if="bottomInfoDayObj(Day).type === 'day'">
                                    <feather-icon
                                      icon="CircleIcon"
                                      :color="bottomInfoDayObj(Day).color"
                                      :fill="bottomInfoDayObj(Day).color"
                                      size="10"
                                    />
                                  </div>
                                  <feather-icon
                                    v-else
                                    icon="CircleIcon"
                                    color="black"
                                    fill="black"
                                  />
                                </div>
                              </template>
                            </van-calendar>
                            <small class="text-danger">{{ validationContext.errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>

                        <b-form-group
                          v-else
                          :label="$t('FormRenting.RentingDate')"
                          label-for="renting-renting-date"
                        >
                          <span class="form-info-box">{{ rentingData.renting_startend_date }}</span>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row>
                      <!-- Field: Time -->
                      <b-col
                        v-if="rentingData.product_id > 0 && rentingData.renting_date !== null && rentingData.renting_date !== '' && rentingData.select.slot_time !== undefined"
                        cols="12"
                        md="12"
                      >
                        <b-form-group
                          v-if="(typeRentingForm === 'Add' || typeRentingForm === 'Edit')"
                          :label="`${$t('FormRenting.Time')} ${$t('PageRenting.Title.StartEnd')}`"
                          label-for="renting-select-time"
                        >
                          <b-row>
                            <b-col
                              cols="6"
                              class="p-0 m-0 px-1 py-25"
                            >
                              <validation-provider
                                #default="validationContext"
                                :name="$t('FormRenting.StartTime')"
                                rules="required"
                              >
                                <b-form-input
                                  v-model="rentingData.select.time.start"
                                  :readonly="true"
                                  class="w-100"
                                />
                                <small class="text-danger">{{ validationContext.errors[0] }}</small>
                              </validation-provider>
                            </b-col>
                            <b-col
                              cols="6"
                              class="p-0 m-0 px-1 py-25"
                            >
                              <validation-provider
                                #default="validationContext"
                                :name="$t('FormRenting.EndTime')"
                                rules="required"
                              >
                                <b-form-input
                                  v-model="rentingData.select.time.end"
                                  :readonly="true"
                                  class="w-100"
                                />
                                <small class="text-danger">{{ validationContext.errors[0] }}</small>
                              </validation-provider>
                            </b-col>
                          </b-row>
                          <div
                            v-for="slot in rentingData.select.slot_time.slot"
                            :key="slot.date"
                          >
                            <div
                              class="mx-1 my-25 px-50 py-50 cursor-pointer"
                              :style="slot.style.slot"
                              style="border-radius: 10px; border: 1px solid #dddddd; background-color: #ffffff;"
                              @click="onClickSlot(slot, rentingData.select.slot_time.slot)"
                            >
                              <b-row>
                                <b-col cols="4">
                                  <div
                                    class="d-flex ml-25"
                                  >
                                    <feather-icon
                                      icon="ClockIcon"
                                      size="18"
                                      style="vertical-align: middle; margin-top: 0.1rem;"
                                    />
                                    <span
                                      class="ml-50 text-header"
                                      :style="slot.style.start"
                                    >
                                      {{ slot.start }}
                                    </span>
                                  </div>
                                </b-col>
                                <b-col cols="4">
                                  <span
                                    class="text-header align-items-center justify-content-center d-flex"
                                    style="color: #666666;"
                                  >
                                    {{ slot.style.text }}
                                  </span>
                                </b-col>
                                <b-col cols="4">
                                  <div
                                    class="d-flex text-right mr-25"
                                    style="float: right;"
                                  >
                                    <feather-icon
                                      icon="ClockIcon"
                                      size="18"
                                      style="vertical-align: middle; margin-top: 0.1rem;"
                                    />
                                    <span
                                      class="ml-50 text-header"
                                      :style="slot.style.end"
                                    >
                                      {{ slot.end }}
                                    </span>
                                  </div>
                                </b-col>
                              </b-row>
                            </div>
                          </div>
                        </b-form-group>

                        <b-form-group
                          v-else
                          :label="$t('FormRenting.Time')"
                          label-for="renting-select-time"
                        >
                          <span class="form-info-box">{{ rentingData.select }}</span>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>

              </div>
            </b-card>
          </div>
        </b-form>
      </div>

    </validation-observer>

    <b-overlay
      :show="busy"
      no-wrap
      @shown="onShown"
      @hidden="onHidden"
    >
      <template #overlay>
        <div
          v-if="processing"
          class="text-center p-2 bg-primary text-light rounded"
        >
          <feather-icon
            icon="UploadCloudIcon"
            size="20"
          />
          <div class="mb-2">
            {{ `${$t('Processing')}...` }}
          </div>
          <b-progress
            min="1"
            max="10"
            :value="counter"
            variant="success"
            height="6px"
            class="mx-n1"
          />
        </div>
        <div
          v-else
          ref="dialog"
          tabindex="-1"
          role="dialog"
          aria-modal="false"
          aria-labelledby="form-confirm-label"
          class="text-center p-3"
        >
          <b-card-text
            class="font-weight-bolder"
          >
            {{ $t('Are you sure?') }}
          </b-card-text>
          <div class="d-flex">
            <b-button
              variant="outline-danger"
              class="mr-3"
              @click="onCancel"
            >
              {{ $t('Cancel') }}
            </b-button>
            <b-button
              variant="outline-success"
              @click="onOK"
            >
              {{ $t('Ok') }}
            </b-button>
          </div>
        </div>
      </template>
    </b-overlay>

    <!-- Product Select -->
    <product-select
      v-model="showProductSelectModal"
      :title="`${$t('Select')} ${$t('FormProduct.Product')}`"
      :product-data="productData"
      :product-filter="productFilter"
      @update-product-select="updateProductSelect"
      @discard-product-select="discardProductSelect"
    />

    <!-- Renting User RoleUser >> Member, Staff >> RoleUser Select -->
    <role-user-select
      v-model="showRentingUserSelectModal"
      :title="`${$t('Select')} ${$t('FormRenting.RentingUser')}`"
      :role-user-data="rentingUserData"
      :role-user-filter="rentingUserFilter"
      @update-role-user-select="updateRentingUserSelect"
      @discard-role-user-select="discardRentingUserSelect"
    />
  </b-modal>
</template>

<script>
import {
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BCard,
  BOverlay,
  BProgress,
  BCardText,
  BInputGroup,
  BInputGroupPrepend,
  BAvatar,
} from 'bootstrap-vue'
import {
  ValidationProvider,
  ValidationObserver,
} from 'vee-validate'
import {
  Dialog,
  // Toast,
  Locale,
  Notify,
} from 'vant'
import formValidation from '@core/comp-functions/forms/form-validation'
import { required } from '@validations'
import {
  onUnmounted,
} from '@vue/composition-api'
import store from '@/store'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import ProductSelect from '@/views/master/product/ProductSelect.vue'
import moment from 'moment'
import { $themeConfig, $themeColors } from '@themeConfig'
import RoleUserSelect from '@/views/booking/role/RoleUserSelect.vue'
import enUS from 'vant/es/locale/lang/en-US'
import thTH from 'vant/es/locale/lang/th-TH'
import tableRentingList from './tableRentingList'
import storeRenting from './storeRenting'

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BOverlay,
    BRow,
    BCol,
    BCard,
    BProgress,
    BCardText,
    BInputGroup,
    BInputGroupPrepend,
    BAvatar,

    ValidationProvider,
    ValidationObserver,
    FeatherIcon,
    vSelect,

    ProductSelect,
    RoleUserSelect,
  },
  model: {
    prop: 'shallShowRentingFormModal',
    event: 'update:shall-show-renting-form-modal',
  },
  props: {
    shallShowRentingFormModal: {
      type: Boolean,
      required: true,
    },
    typeRentingForm: {
      type: String,
      default: 'Add',
    },
    rentingData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem(`${$themeConfig.app.commonSession}-user-data`)),
      required,
      busy: false,
      processing: false,
      counter: 1,
      interval: null,
      skin: store.state.appConfig.layout.skin,

      // product
      showProductSelectModal: false,
      productData: {},
      productFilter: {
        product_type: '',
        category: '',
        q: '',
      },

      // renting user
      showRentingUserSelectModal: false,
      rentingUserData: {},
      rentingUserFilter: {},

      // for calendar
      showRentingDate: false,
      topInfoDay: [],
      bottomInfoDay: null,
      defaultDateCalendar: null,
      typeCalendar: 'single',
      showButtonConfirmCalendar: false,

      // for renting list
      timeData: {},
      timeList: [],

      rentingDay: '',
      rentingDayOption: [],
      // for summary renting
      summaryData: {},
    }
  },
  computed: {
    lineOa() {
      const value = store.state.lineapi.lineOa
      if (value === '' || value === undefined) {
        return ''
      }
      return JSON.parse(value)
    },
    lineOaId() {
      const value = this.lineOa.id
      if (value === '' || value === undefined) {
        return ''
      }
      return value
    },
    minDateCalendar() {
      const minDate = moment().toDate()
      return minDate
    },
    maxDateCalendar() {
      const maxDate = moment().add(12, 'months').toDate()
      return maxDate
    },
    locale() {
      return this.$i18n.locale
    },
    summaryTotalDay() {
      if (this.summaryData.totalDay !== undefined) return `${this.summaryData.totalDay} ${this.$i18n.t('Day')}`
      return ''
    },
    summaryTotalTime() {
      if (this.summaryData.totalHour !== undefined) {
        if (this.summaryData.totalHour === 0 && this.summaryData.totalMinute > 0) return `${this.summaryData.totalMinute} ${this.$i18n.t('Minute')}`
        if (this.summaryData.totalHour > 0 && this.summaryData.totalMinute > 0) return `${this.summaryData.totalHour} ${this.$i18n.t('Hour')} ${this.summaryData.totalMinute} ${this.$i18n.t('Minute')}`
        return `${this.summaryData.totalHour} ${this.$i18n.t('Hour')}`
      }
      return ''
    },
    summaryTotalPrice() {
      if (this.summaryData.totalPrice !== undefined) return `${this.summaryData.totalPrice} ${this.$i18n.t('Baht')}`
      return ''
    },
  },
  watch: {
    'rentingData.event_type': {
      handler(value) {
        this.rentingData.event = {
          name: '',
          value: '',
        }
        this.rentingData.name = ''
        this.rentingData.product.type_code = ''
        this.rentingData.product_type.name = ''
        this.rentingData.product_id = ''
        this.rentingData.type_code = ''
        this.rentingData.category_name = ''
        this.rentingData.department_name = ''
        this.rentingData.renting_amount = 0
        if (value === 'renting') {
          this.rentingData = {}
        } else if (value === 'renting') {
          this.rentingData = {}
        }
      },
    },
    shallShowRentingFormModal(value) {
      if (value === true) {
        console.log('shallShowRentingFormModal')
        this.defaultDateCalendar = this.getDefaultDate()
      }
    },
    'rentingData.renting_amount': {
      handler() {
        if (this.rentingData.renting_amount !== '' && this.rentingData.discount !== '') {
          const amount = parseFloat(this.rentingData.renting_amount) - parseFloat(this.rentingData.discount)
          this.rentingData.amount = amount
        } else {
          this.rentingData.amount = 0
        }
      },
    },
    'rentingData.discount': {
      handler() {
        if (this.rentingData.renting_amount !== '' && this.rentingData.discount !== '') {
          const amount = parseFloat(this.rentingData.renting_amount) - parseFloat(this.rentingData.discount)
          this.rentingData.amount = amount
        } else {
          this.rentingData.amount = 0
        }
      },
    },
    'rentingData.fee_type': {
      handler(value) {
        if (value !== undefined) {
          switch (value) {
            default:
            case 'hourly':
              this.typeCalendar = 'single'
              this.showButtonConfirmCalendar = false
              break
            case 'daily':
              this.typeCalendar = 'single'
              this.showButtonConfirmCalendar = false
              break
            case 'range':
              this.typeCalendar = 'range'
              this.showButtonConfirmCalendar = true
              break
          }
          this.rentingData.type_calendar = this.typeCalendar
          this.rentingData.fee_type_code = value
          this.rentingData.renting_date = ''
          this.rentingData.renting_startend_date = ''
          if (this.bottomInfoDay !== null) {
            this.defaultDateCalendar = this.getDefaultDate()
          }
        }
      },
    },
    bottomInfoDay() {
      this.defaultDateCalendar = this.getDefaultDate()
    },
    'rentingData.product_id': {
      handler(value) {
        if (value !== '' && value > 0) {
          this.rentingList = []
          if (this.rentingData.fee_type_code === undefined || this.rentingData.fee_type_code === '') {
            this.rentingData.fee_type_code = 'hourly'
          }
          if (this.rentingData.type_calendar === undefined) {
            this.rentingData.type_calendar = this.typeCalendar
          } else if (this.rentingData.type_calendar === 'range') {
            this.typeCalendar = this.rentingData.type_calendar
          }
          this.getCalendarRenting()
        }
      },
    },
    'rentingData.select.time.start': {
      handler() {
        if (this.rentingData.select.time.start !== '' && this.rentingData.select.time.end !== '') {
          this.getSummaryRenting()
        } else {
          this.summaryData = {}
        }
      },
    },
    'rentingData.select.time.end': {
      handler() {
        if (this.rentingData.select.time.start !== '' && this.rentingData.select.time.end !== '') {
          this.getSummaryRenting()
        } else {
          this.summaryData = {}
        }
      },
    },
  },
  mounted() {
    if (this.locale === 'en') {
      Locale.use('en-US', enUS)
    } else if (this.locale === 'th') {
      Locale.use('th-TH', thTH)
    }
  },
  methods: {
    onShown() {
      // Focus the dialog prompt
      this.$refs.dialog.focus()
    },
    onHidden() {
      // In this case, we return focus to the submit button
      // You may need to alter this based on your application requirements
      this.$refs.submit.focus()
    },
    onSubmit() {
      this.$refs.refFormObserver.validate().then(success => {
        if (success) {
          this.processing = false
          this.busy = true
        }
      })
    },
    onCancel() {
      this.busy = false
    },
    onOK() {
      this.processing = true
      if (this.typeRentingForm === 'Add') {
        store.dispatch('store-renting-form/addRenting', this.rentingData)
          .then(() => {
            const okMsg = this.$i18n.t('Process on successfully')
            this.$toast({
              component: ToastificationContent,
              props: {
                title: okMsg,
                icon: 'CheckCircleIcon',
                variant: 'success',
              },
            },
            {
              position: 'bottom-right',
            })

            if (this.typeRentingForm === 'Add') {
              this.$swal({
                icon: 'success',
                title: this.$i18n.t('Added'),
                text: this.$i18n.t('Your data has been added'),
                timer: 3000,
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
            }
            this.busy = false
            this.processing = false

            this.$emit('renting-form-update')
            this.$emit('update:shall-show-renting-form-modal', false)
          })
          .catch(error => {
            const { response, message } = error
            if (response) {
              const errorMsg = this.$i18n.t(response?.data.error)
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: errorMsg,
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              },
              {
                position: 'bottom-right',
              })
            } else if (message) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: error?.message,
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              },
              {
                position: 'bottom-right',
              })
            }
            this.busy = false
            this.processing = false
          })
      } else if (this.typeRentingForm === 'Edit') {
        store.dispatch('store-renting-form/editRenting', { id: this.rentingData.id, data: this.rentingData })
          .then(response => {
            let uploadFound = 0
            const uploadImage = this.rentingFile.image
            uploadImage.forEach(item => {
              if (item.file !== undefined) {
                uploadFound += 1
              }
            })

            if (uploadFound > 0) {
              this.uploadImageRentingImage(response.data.id)
            } else {
              // not upload image file
              const okMsg = this.$i18n.t('Process on successfully')
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: okMsg,
                  icon: 'CheckCircleIcon',
                  variant: 'success',
                },
              },
              {
                position: 'bottom-right',
              })
              this.busy = false
              this.processing = false

              this.$emit('renting-form-update')
              this.$emit('update:shall-show-renting-form-modal', false)
            }
          })
          .catch(error => {
            const { response, message } = error
            if (response) {
              const errorMsg = this.$i18n.t(response?.data.error)
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: errorMsg,
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              },
              {
                position: 'bottom-right',
              })
            } else if (message) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: error?.message,
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              },
              {
                position: 'bottom-right',
              })
            }
            this.busy = false
            this.processing = false
          })
      }
    },
    onDiscard() {
      this.$emit('discard-renting-form')
      this.$emit('update:shall-show-renting-form-modal', false)
    },
    selectProduct() {
      if (this.rentingData.product.id === '') {
        this.productData = {}
      } else {
        this.productData = {}
        this.productData.id = this.rentingData.product.id
        this.productData.code = this.rentingData.product.code
        this.productData.name = this.rentingData.name
        this.productData.type = this.rentingData.type_code
      }
      this.productFilter = {
        product_type: '',
        category: '',
        q: '',
      }
      this.showProductSelectModal = true
    },
    updateProductSelect(data) {
      this.$nextTick(() => {
        this.rentingData.product.id = data.select.information.id
        this.rentingData.product.name = data.select.information.name
        this.rentingData.product.code = data.select.information.code
        this.rentingData.product.type = data.select.information.product_type.code
        this.rentingData.product_id = data.select.information.id
        this.rentingData.product.code = data.select.information.code
        this.rentingData.name = data.select.information.name
        this.rentingData.product.type_code = data.select.information.product_type.code
        this.rentingData.product_type.name = data.select.information.product_type.name
        this.rentingData.type_code = data.select.information.type_code
        this.rentingData.category_id = data.select.information.category_id
        this.rentingData.category_name = data.select.information.category_name
        this.rentingData.department_id = data.select.information.department_id
        this.rentingData.department_name = data.select.information.department_name
        this.rentingData.approver_user_id = data.select.information.approver_user.value

        this.productData.id = data.select.information.id
        this.productData.code = data.select.information.code
        this.productData.name = data.select.information.name
        this.productData.type = data.select.information.product_type.code

        this.$refs.refForm.focus()
      })
    },
    discardProductSelect() {
    },
    IsNumber(event) {
      if (!/\d/.test(event.key)) return event.preventDefault()
      return null
    },
    selectRentingUser() {
      if (this.rentingData.renting_user.id === '') {
        this.rentingUserData = {}
        this.rentingUserData.role_code = ['member', 'staff', 'approver']
      } else {
        this.rentingUserData = {}
        this.rentingUserData.id = this.rentingData.renting_user.id
        this.rentingUserData.name = this.rentingData.renting_user.name
        this.rentingUserData.role_code = ['member', 'staff', 'approver']
      }
      this.showRentingUserSelectModal = true
    },
    updateRentingUserSelect(data) {
      this.$nextTick(() => {
        this.rentingData.renting_user.id = data.select.user_id
        this.rentingData.renting_user.name = data.select.user_name
        this.rentingData.renting_user.email = data.select.user_email
        this.rentingData.renting_user.line_user_id = data.select.line_user.line_user_id

        this.rentingUserData.id = this.rentingData.renting_user.id
        this.rentingUserData.name = this.rentingData.renting_user.name

        this.rentingData.renting_user_id = data.select.user_id
        this.rentingData.renting_user_name = data.select.user_name
        this.rentingData.renting_user_email = data.select.user_email
        this.rentingData.user_id = data.select.user_id
        this.rentingData.line_user_id = data.select.line_user.line_user_id
        this.rentingData.line_oa_id = this.lineOaId
        this.rentingData.line_user = data.select.line_user

        this.rentingData.renting_user_select = `${this.rentingData.line_user.display_name} (${this.rentingData.renting_user.name})`
      })
    },
    discardRentingUserSelect() {
    },
    // default date
    // if close day (saturday, sunday) skip to monday, sunday
    getDefaultDate() {
      this.processing = true
      // close day (saturday, sunday)
      let dateNow = moment().toDate()

      let defaultDate = dateNow
      this.rentingData.renting_startend_date = `${moment().format('DD-MM-YYYY')}`
      if (this.rentingData.product_id > 0) {
        const dayNow = moment().format('d')
        const closeSaturday = (this.rentingData.product.close_saturday) ? this.rentingData.product.close_saturday : '0'
        const closeSunday = (this.rentingData.product.close_sunday) ? this.rentingData.product.close_sunday : '0'
        if (dayNow === '6') {
          if (closeSaturday === '1' && closeSunday === '1') {
            // skip to monday +2
            dateNow = moment().add('2', 'days').toDate()
          } else if (closeSaturday === '1' && closeSunday === '0') {
            // skip to sunday +1
            dateNow = moment().add('1', 'days').toDate()
          }
        } else if (dayNow === '0') {
          if (closeSunday === '1') {
            // skip to monday +1
            dateNow = moment().add('1', 'days').toDate()
          }
        }

        // type calendar default from form data
        if (this.rentingData.type_calendar !== undefined) {
          this.typeCalendar = this.rentingData.type_calendar
        }

        // default date today (skip saturday, sunday)
        if (this.typeCalendar === 'single') {
          // single select on calendar
          defaultDate = dateNow
        } else if (this.typeCalendar === 'range') {
          // range select on calendar
          // start/end same date
          defaultDate = [dateNow, dateNow]
        }

        // found renting date from form data
        // type calendar not change
        if (this.rentingData.renting_date !== null && this.rentingData.renting_date !== undefined && this.rentingData.renting_date !== '') {
          console.log(this.rentingData.renting_date)
          if (Object.prototype.toString.call(this.rentingData.renting_date) === '[object String]') {
            console.log('is string')
            this.rentingData.renting_date = new Date(this.rentingData.renting_date)
            console.log(this.rentingData.renting_date)
          }
          // set default date
          defaultDate = this.rentingData.renting_date
          // set renting id in default date
          this.setRentingId(defaultDate)
        } else {
          // not found renting date
          // default today or skip sat/sun
          this.rentingData.renting_date = defaultDate
          // set renting id in default date
          this.setRentingId(defaultDate)
        }
      }
      this.processing = false
      return defaultDate
    },
    // for view layout calendar
    formatterCalendar(day) {
      if (day.type === 'start') {
        day.bottomInfo = this.$i18n.t('Start')
      } else if (day.type === 'end') {
        day.bottomInfo = this.$i18n.t('End')
      }

      const closeSaturday = (this.rentingData.product.close_saturday) ? this.rentingData.product.close_saturday : '0'
      if (closeSaturday === '1') {
        if (day.date.getDay() === 6) {
          day.type = 'disabled'
        }
      }
      const closeSunday = (this.rentingData.product.close_sunday) ? this.rentingData.product.close_sunday : '0'
      if (closeSunday === '1') {
        if (day.date.getDay() === 0) {
          day.type = 'disabled'
        }
      }
      return day
    },
    // eslint-disable-next-line no-unused-vars
    onSelectCalendar(day) {
      if (this.typeCalendar !== 'range') {
        this.showRentingDate = false
        this.rentingData.renting_date = day
        this.setRentingId(day)
      }
    },
    onConfirmCalendar(day) {
      this.showRentingDate = false
      this.rentingData.renting_date = day
      this.setRentingId(day)
    },
    setRentingId(day) {
      const rentingId = []
      if (Array.isArray(day) === true) {
        // is array
        // from day - to day
        const from = (day[0] !== null) ? moment(day[0]).toDate() : null
        const to = (day[1] !== null) ? moment(day[1]).toDate() : from
        if (from !== null && to !== null) {
          const startDate = moment(from).format('YYYY-MM-DD')
          const endDate = moment(to).format('YYYY-MM-DD')
          const currentDate = moment(startDate)

          this.rentingData.renting_startend_date = `${moment(from).format('DD-MM-YYYY')} - ${moment(to).format('DD-MM-YYYY')}`

          // loop start day to end day
          while (currentDate.isSameOrBefore(endDate, 'day')) {
            const cDate = currentDate.format('YYYY-MM-DD')
            // get renting id in day
            const id = this.getRentingId(cDate)
            if (Array.isArray(id) && id.length > 0) {
              rentingId.push({
                date: moment(cDate).format('YYYY-MM-DD'),
                id,
              })
            }
            // add one day
            currentDate.add(1, 'day')
          }
        }
      } else {
        // not array
        // one day
        this.rentingData.renting_startend_date = moment(day).format('DD-MM-YYYY')
        const id = this.getRentingId(day)
        if (Array.isArray(id) && id.length > 0) {
          rentingId.push({
            date: moment(day).format('YYYY-MM-DD'),
            id,
          })
        }
      }
      this.rentingData.renting_id = rentingId
      this.getTimeRenting()
    },
    getRentingId(day) {
      const id = []
      const date = moment(day).format('YYYY-MM-DD')
      if (this.bottomInfoDay === null) return []
      const found = this.bottomInfoDay.filter(e => e.date === date)
      if (Array.isArray(found)) {
        if (found.length > 0) {
          // found
          found.forEach(item => {
            if (item.id !== undefined) {
              id.push(item.id)
            }
          })
        }
      }
      return id
    },
    topInfoDayFound(day) {
      return this.topInfoDay.some(
        val => moment(val).format('YYYY-MM-DD')
          === moment(day.date).format('YYYY-MM-DD'),
      )
    },
    bottomInfoDayFound(day) {
      if (this.bottomInfoDay === null) return false
      return this.bottomInfoDay.some(
        val => moment(val.date).format('YYYY-MM-DD')
          === moment(day.date).format('YYYY-MM-DD'),
      )
    },
    bottomInfoDayObj(day) {
      if (this.bottomInfoDay === null) return false
      const obj = this.bottomInfoDay.find(
        val => moment(val.date).format('YYYY-MM-DD')
          === moment(day.date).format('YYYY-MM-DD'),
      )
      if (obj) {
        if (obj.variant === undefined) {
          obj.variant = 'secondary'
        }
        if (obj.color === undefined) {
          obj.color = 'black'
        }
      }
      return obj
    },
    selectRentingDate() {
      this.showRentingDate = true
    },
    getCalendarRenting() {
      if (this.lineOaId !== '') {
        this.processing = true
        const params = {
          language: this.locale,
          lineOaId: this.lineOaId,
          productId: this.rentingData.product_id,
          minDate: moment(this.minDateCalendar).format('YYYY-MM-DD'),
          maxDate: moment(this.maxDateCalendar).format('YYYY-MM-DD'),
        }
        store.dispatch('store-renting-form/getCalendarRenting', params)
          .then(response => {
            this.busy = false
            this.processing = false
            this.rentingList = response.data

            // loop renting list from min to max date
            // add to bottom info day for calendar
            this.bottomInfoDay = []
            this.rentingList.forEach(itemList => {
              const statusColor = (itemList.status_color) ? itemList.status_color : ''
              // one day, or range date
              if (itemList.start_date.value === itemList.end_date.value) {
                // one day
                this.bottomInfoDay.push({
                  date: itemList.end_date.value,
                  type: 'day',
                  color: this.iconColor(statusColor),
                  variant: '',
                  id: itemList.id,
                })
              } else if (itemList.start_date.value !== itemList.end_date.value) {
                // range
                // add start date
                this.bottomInfoDay.push({
                  type: 'start',
                  date: itemList.start_date.value,
                  variant: statusColor,
                  color: '',
                  id: itemList.id,
                })
                // add end date
                this.bottomInfoDay.push({
                  type: 'end',
                  date: itemList.end_date.value,
                  variant: statusColor,
                  color: '',
                  id: itemList.id,
                })
                const startDate = moment(itemList.start_date.value)
                const endDate = moment(itemList.end_date.value)
                const totalDay = endDate.diff(startDate, 'days')
                // eslint-disable-next-line no-plusplus
                for (let i = 1; i < totalDay; i++) {
                  const newDate = moment(startDate).add(i, 'days').format('YYYY-MM-DD')
                  // add middle date
                  this.bottomInfoDay.push({
                    type: 'middle',
                    date: newDate,
                    variant: statusColor,
                    color: '',
                    id: itemList.id,
                  })
                }
              }
            })
          })
          .catch(error => {
            const { response, message } = error
            if (response) {
              const errorMsg = this.$i18n.t(response?.data.error)
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: errorMsg,
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              },
              {
                position: 'bottom-right',
              })
            } else if (message) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: error?.message,
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              },
              {
                position: 'bottom-right',
              })
            }
            this.busy = false
            this.processing = false
          })
      }
    },
    getTimeRenting() {
      if (this.lineOaId !== '' && this.rentingData.product_id > 0) {
        this.processing = true
        const params = {
          language: this.locale,
          lineOaId: this.lineOaId,
          productId: this.rentingData.product_id,
          rentingDate: this.rentingData.renting_date,
          rentingId: this.rentingData.renting_id,
        }
        store.dispatch('store-renting-form/getTimeRenting', params)
          .then(response => {
            this.processing = false
            this.timeData = response.data
            if (this.timeData.list) {
              this.timeList = this.timeData.list

              this.timeList.forEach(list => {
                list.slot.forEach(ss => {
                  ss.style = this.getSlotTimeStyle(ss)
                })
              })
            }
            this.rentingDay = this.timeData.date

            this.rentingData.select.day = this.rentingDay
            this.rentingData.select.state = 'start'
            this.rentingData.select.time = {
              start: '',
              end: '',
            }
            this.rentingData.select.slot_time = this.timeList.find(e => e.date === this.rentingDay)
          })
          .catch(error => {
            const { response, message } = error
            if (response) {
              const errorMsg = this.$i18n.t(response?.data.error)
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: errorMsg,
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              },
              {
                position: 'bottom-right',
              })
            } else if (message) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: error?.message,
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              },
              {
                position: 'bottom-right',
              })
            }
            this.busy = false
            this.processing = false
          })
      }
    },
    getSlotTimeStyle(slot) {
      const style = {
        slot: '',
        start: '',
        end: '',
        text: '',
      }
      if (slot.break === 1) {
        style.slot = 'background-color: #dddddd50;'
        style.start = 'color: #000000;'
        style.end = 'color: #000000;'
        style.text = this.$i18n.t('PageRenting.Title.Break')
      } else if (slot.booked === 1) {
        style.slot = `background-color: ${this.iconColor('primary')}50;`
        style.start = 'color: #000000;'
        style.end = 'color: #000000;'
        style.text = this.$i18n.t('PageRenting.Title.Booked')
      } else if (slot.renting === 1) {
        // start
        style.slot = `background-color: ${this.iconColor('info')}50;`
        style.start = 'color: #000000;'
        style.end = 'color: #000000;'
        style.text = this.$i18n.t('PageRenting.Title.Start')
      } else if (slot.renting === 2) {
        // between
        style.slot = `background-color: ${this.iconColor('info')}25;`
        style.start = 'color: #000000;'
        style.end = 'color: #000000;'
        style.text = this.$i18n.t('PageRenting.Title.Between')
      } else if (slot.renting === 3) {
        // end
        style.slot = `background-color: ${this.iconColor('info')}50;`
        style.start = 'color: #000000;'
        style.end = 'color: #000000;'
        style.text = this.$i18n.t('PageRenting.Title.End')
      } else if (slot.renting === 4) {
        // start = end
        style.slot = `background-color: ${this.iconColor('info')}50;`
        style.start = 'color: #000000;'
        style.end = 'color: #000000;'
        style.text = this.$i18n.t('PageRenting.Title.StartEnd')
      }

      return style
    },
    onClickSlot(slot, slotList) {
      // console.log(slot)
      if (slot.break === 1 || slot.booked === 1) {
        Notify({
          message: this.$i18n.t('PageRenting.Message.Error, Can not be selected'),
          color: `${this.iconColor('light')}`,
          background: `${this.iconColor('danger')}`,
          duration: 3000,
        })
      } else {
        let canSelect = false
        if (this.rentingData.select.state === 'start') {
          // set select start
          this.rentingData.select.time.start = slot.start
          this.rentingData.select.state = 'end'
          slot.renting = 1
          // clear style slot and set style start select
          slotList.forEach(list => {
            list.style = this.getSlotTimeStyle(list)
          })
          canSelect = true
        } else if (this.rentingData.select.state === 'end') {
          const startSelect = this.rentingData.select.time.start
          const endSelect = slot.start
          // check end less start?
          if (startSelect > endSelect) {
            // end less than start is not ok
            Notify({
              message: this.$i18n.t('PageRenting.Message.Error, Can not be selected'),
              color: `${this.iconColor('light')}`,
              background: `${this.iconColor('danger')}`,
              duration: 3000,
            })
          } else {
            // end more than start is ok
            // check over period for 'end'
            // loop check over booked?
            let doCheck = false
            let isOver = false
            slotList.forEach(list => {
              if (list.start === startSelect && doCheck === false) {
                doCheck = true
                if (list.break === 1 || list.booked === 1) {
                  isOver = true
                }
              } else if (list.start !== startSelect && startSelect !== endSelect && doCheck === true) {
                if (list.booked === 1) {
                  isOver = true
                } else if (list.start === endSelect) {
                  if (list.break === 1 || list.booked === 1) {
                    isOver = true
                  } else {
                    // end
                    this.rentingData.select.time.end = list.start
                    this.rentingData.select.state = 'done'
                    this.rentingData.select.day = this.rentingDay
                    list.renting = 3
                    list.style = this.getSlotTimeStyle(list)
                  }
                  doCheck = false
                } else {
                  // between
                  list.renting = 2
                  list.style = this.getSlotTimeStyle(list)
                }
              }
              if (startSelect === endSelect && doCheck === true) {
                if (list.break === 1 || list.booked === 1) {
                  isOver = true
                } else {
                  // start & end
                  this.rentingData.select.time.end = list.start
                  this.rentingData.select.state = 'done'
                  this.rentingData.select.day = this.rentingDay
                  list.renting = 4
                  list.style = this.getSlotTimeStyle(list)
                }
                doCheck = false
              }
            })

            if (isOver === true) {
              this.rentingData.select.state = 'end'
              canSelect = false
              // clear style slot and set style start select
              slotList.forEach(list => {
                if (list.start === startSelect) {
                  list.renting = 1
                  list.style = this.getSlotTimeStyle(list)
                } else {
                  list.renting = 0
                  list.style = this.getSlotTimeStyle(list)
                }
              })
            } else {
              canSelect = true
            }
          }
        } else if (this.rentingData.select.state === 'done') {
          // done
          Dialog.confirm({
            title: this.$i18n.t('Confirmation'),
            message: this.$i18n.t('Do you want to select again?'),
            cancelButtonText: this.$i18n.t('No'),
            confirmButtonText: this.$i18n.t('Yes'),
            confirmButtonColor: this.iconColor('primary'),
          })
            .then(() => {
              // on confirm to clear select again
              // clear style slot and new select
              slotList.forEach(list => {
                list.renting = 0
                list.style = this.getSlotTimeStyle(list)
              })
              this.rentingData.select.time.start = ''
              this.rentingData.select.time.end = ''
              this.rentingData.select.state = 'start'
              this.rentingData.select.day = ''
            })
            .catch(() => {
              // on no
            })
        }
        if (canSelect === false) {
          Notify({
            message: this.$i18n.t('PageRenting.Message.Error, Can not be selected'),
            color: `${this.iconColor('light')}`,
            background: `${this.iconColor('danger')}`,
            duration: 3000,
          })
        }
      }
    },
    getSummaryRenting() {
      if (this.lineOaId !== '' && this.rentingData.product_id > 0) {
        this.processing = true
        const params = {
          language: this.locale,
          lineOaId: this.lineOaId,
          productId: this.rentingData.product_id,
          rentingData: this.rentingData,
        }
        store.dispatch('store-renting-form/getSummaryRenting', params)
          .then(response => {
            this.processing = false
            this.summaryData = response.data
            if (this.summaryData.fee) {
              this.rentingData.fee_amount = this.summaryData.fee.totalPrice
              this.rentingData.fee_qty = this.summaryData.fee.feeQty
              this.rentingData.fee_qty_unit = this.summaryData.fee.feeQtyUnit
              this.rentingData.fee_value = this.summaryData.fee.feeValue
              this.rentingData.fee_type_code = this.summaryData.fee.feeTypeCode
            }
            if (this.summaryData.totalDay) {
              this.rentingData.total_day = this.summaryData.totalDay
            }
            if (this.summaryData.totalHour) {
              this.rentingData.total_hour = this.summaryData.totalHour
            }
            if (this.summaryData.totalMinute) {
              this.rentingData.total_minute = this.summaryData.totalMinute
            }
            if (this.summaryData.totalPrice) {
              this.rentingData.total_price = this.summaryData.totalPrice
            }
          })
          .catch(error => {
            const { response, message } = error
            if (response) {
              const errorMsg = this.$i18n.t(response?.data.error)
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: errorMsg,
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              },
              {
                position: 'bottom-right',
              })
            } else if (message) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: error?.message,
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              },
              {
                position: 'bottom-right',
              })
            }
            this.busy = false
            this.processing = false
          })
      } else {
        this.processing = true
        this.showOverlay = false
      }
    },
  },
  setup() {
    if (!store.hasModule('store-renting-form')) store.registerModule('store-renting-form', storeRenting)
    onUnmounted(() => {
      if (store.hasModule('store-renting-form')) store.unregisterModule('store-renting-form')
    })
    const {
      refFormObserver,
      getValidationState,
      resetForm,
      clearForm,
    } = formValidation()

    const {
      isStatusVariant,
      isStatusToText,
      textFirstUpper,
      numberFormat,
    } = tableRentingList()

    const iconColor = color => $themeColors[color]

    return {
      // Form Validation
      resetForm,
      clearForm,
      refFormObserver,
      getValidationState,

      isStatusVariant,
      isStatusToText,
      textFirstUpper,
      numberFormat,

      iconColor,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss">
@import '~@core/scss/base/bootstrap-extended/_variables';
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

.modal{
  .line-height-info{
    padding-bottom: 0.6rem;
    &.end{
      padding-bottom: 0;
    }
  }
  .modal-header,
  .modal-header[class*='bg-'] {
    background-color: #fff !important;
  }
  .modal-body {
    background-color: $body-bg !important;
  }
}
.form-info-box{
  display: flex;
  padding: 0.6rem 1rem;
  background-color: #f8f8f8;
  background-clip: padding-box;
  border: 1px solid #f0f0f0;
  border-radius: 0.357rem;
}

.dark-layout {
  .modal{
    .line-height-info{
      padding-bottom: 0.6rem;
      &.end{
        padding-bottom: 0;
      }
    }
    .modal-header,
    .modal-header[class*='bg-'] {
      background-color: $theme-dark-body-bg !important;
    }
    .modal-body {
      background-color: $theme-dark-table-active-bg !important;
    }
  }
  .form-info-box{
    display: flex;
    padding: 0.6rem 1rem;
    background-color: $theme-dark-input-bg;
    background-clip: padding-box;
    border: 1px solid $theme-dark-border-color;
    border-radius: 0.357rem;
  }
}
.jsoneditor-poweredBy {
  display: none !important;
}
.jsoneditor {
  border: thin solid #d8d6de !important;
}
.jsoneditor-menu{
  background-color: #aaaaaa !important;
  border-bottom: 1px solid #aaaaaa !important;
}
</style>

<style lang="scss">
.preview-cover {
  position: absolute;
  bottom: 0;
  box-sizing: border-box;
  width: 100%;
  padding: 4px;
  color: #fff;
  font-size: 12px;
  text-align: center;
  background: rgba(0, 0, 0, 0.3);
}

.van-uploader__upload {
  width: 180px;
  height: 180px;
}
.van-uploader__preview-image {
  width: 180px;
  height: 180px;
}
</style>
