<template>
  <div
    v-if="$can('read', 'ModuleBooking.Renting')"
    style="height: inherit"
  >
    <b-overlay
      :show="showOverlay"
      rounded="sm"
    >
      <!-- Filters -->
      <renting-list-filters
        :collapsed-filter.sync="collapsedFilter"
        :product-type-filter.sync="productTypeFilter"
        :product-category-filter.sync="productCategoryFilter"
        :product-filter.sync="productFilter"
        :renting-date-filter.sync="rentingDateFilter"
        :renting-code-filter.sync="rentingCodeFilter"
        :name-filter.sync="nameFilter"
        :status-filter.sync="statusFilter"
        :payment-status-filter.sync="paymentStatusFilter"
        :product-type-options.sync="productTypeOptions"
        :product-category-options.sync="productCategoryOptions"
        :product-options.sync="productOptions"
        :status-options.sync="statusOptions"
        :payment-status-options.sync="paymentStatusOptions"
        @reset-filter="resetFilter"
      />

      <!-- Table Container Card -->
      <b-card
        no-body
      >
        <div class="m-2">

          <!-- Table Top -->
          <b-row>

            <!-- Per Page -->
            <b-col
              cols="12"
              sm="12"
              md="4"
            >
              <label>{{ $t('Entries') }}</label>
              <v-select
                v-model="perPage"
                :options="perPageOptions"
                :clearable="false"
                class="per-page-selector d-inline-block ml-50 mr-1"
              />
            </b-col>

            <!-- Search -->
            <b-col
              cols="12"
              sm="12"
              md="8"
            >
              <div class="d-flex align-items-center mt-1 mt-md-0">
                <b-button
                  v-if="$can('create', 'ModuleBooking.Renting')"
                  variant="primary"
                  class="w-50 mx-50"
                  @click="addRentingForm"
                >
                  {{ `${$t('Add')} ${$t('TitleBooking.Renting')}` }}
                </b-button>
                <b-button
                  v-if="$can('create', 'ModuleBooking.Renting')"
                  variant="success"
                  class="w-50 mx-0"
                  @click="onRentingReport"
                >
                  {{ `${$t('Report')} ${$t('TitleBooking.Renting')}` }}
                </b-button>
                <b-form-input
                  v-model="searchQuery"
                  class="d-inline-block mr-1 ml-1"
                  :placeholder="`${$t('Search')}...`"
                />
              </div>
            </b-col>
          </b-row>

        </div>
        <b-table
          ref="refRentingListTable"
          :items="getListRenting"
          responsive
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="sortBy"
          show-empty
          hover
          empty-text="No matching records found"
          :sort-desc.sync="isSortDirDesc"
          class="position-relative"
          selectable
          select-mode="multi"
        >
          <!-- Column: id -->
          <template #cell(id)="data">
            <div style="margin-top: 0.3rem;">
              <b-list-group>
                <b-list-group-item
                  style="padding: 0.2rem 0.6rem 0.2rem 0.6rem;"
                >
                  <b-avatar
                    :src="data.item.picture_url"
                    size="32px"
                    variant="light-primary"
                    badge
                    class="badge-minimal"
                    badge-variant="success"
                  />
                  <span
                    class="ml-50"
                    style="font-size: 14px;"
                  >
                    {{ data.item.display_name }}
                  </span>
                </b-list-group-item>
              </b-list-group>
            </div>
            <div style="margin-top: 0.3rem;">
              <b-list-group>
                <b-list-group-item
                  style="padding: 0.2rem 0.6rem 0.2rem 0.6rem;"
                >
                  <span style="margin-right: 0.6rem;">
                    {{ $t('ID') }} »
                  </span>
                  <span style="font-size: 14px;">
                    <b-badge
                      pill
                      :variant="(skin === 'dark')? `light-info` : `light-info` "
                      class="text-capitalize"
                    >
                      {{ data.item.id }}
                    </b-badge>
                  </span>
                </b-list-group-item>
              </b-list-group>
            </div>
            <!-- Status -->
            <div style="margin-top: 0.3rem;">
              <b-list-group>
                <b-list-group-item
                  style="padding: 0.2rem 0.6rem 0.2rem 0.6rem;"
                >
                  <span style="margin-right: 0.6rem;">
                    {{ $t('Status') }} »
                  </span>
                  <span style="font-size: 14px;">
                    <b-badge
                      pill
                      :variant="(skin === 'dark')? `light-${isStatusVariant(data.item.status)}` : `light-${isStatusVariant(data.item.status)}` "
                      class="text-capitalize"
                    >
                      {{ $t(`StatusMessage.${data.item.status}`) }}
                    </b-badge>
                  </span>
                </b-list-group-item>
              </b-list-group>
            </div>
            <div style="margin-top: 0.3rem;">
              <b-list-group>
                <b-list-group-item
                  style="padding: 0.2rem 0.6rem 0.2rem 0.6rem;"
                >
                  <span style="margin-right: 0.6rem;">
                    {{ $t('FormRenting.Remark') }} »
                  </span>
                  <span style="font-size: 14px;">
                    {{ data.item.remark }}
                  </span>
                </b-list-group-item>
              </b-list-group>
            </div>
            <div style="margin-top: 0.3rem;">
              <b-list-group>
                <b-list-group-item
                  style="padding: 0.2rem 0.6rem 0.2rem 0.6rem;"
                >
                  <span style="margin-right: 0.6rem;">
                    {{ $t('FormRenting.FeeType') }} »
                  </span>
                  <span style="font-size: 14px;">
                    {{ $t(`FeeTypeTitle.${data.item.fee_type_code}`) }}
                  </span>
                </b-list-group-item>
                <b-list-group-item
                  style="padding: 0.2rem 0.6rem 0.2rem 0.6rem;"
                >
                  <span style="margin-right: 0.6rem;">
                    {{ $t('FormRenting.Fee') }} »
                  </span>
                  <span style="font-size: 14px;">
                    {{ data.item.fee_qty }}
                    {{ $t(`FeeType.${data.item.fee_type_code}`) }}
                  </span>
                </b-list-group-item>
                <b-list-group-item
                  style="padding: 0.2rem 0.6rem 0.2rem 0.6rem;"
                >
                  <span style="margin-right: 0.6rem;">
                    {{ `${$t('FormRenting.TotalAmount')} (${$t('FormRenting.Baht')})` }} »
                  </span>
                  <span style="font-size: 14px;">
                    <b-badge
                      pill
                      :variant="`light-${data.item.payment_status_color}`"
                      class="px-1 py-50"
                      style="font-size: 16px;"
                    >
                      {{ data.item.amount_show }}
                    </b-badge>
                  </span>
                </b-list-group-item>
              </b-list-group>
            </div>
          </template>

          <!-- Column: renting -->
          <template #cell(renting)="data">
            <!-- Renting Code -->
            <div style="margin-top: 0.3rem;">
              <b-list-group>
                <b-list-group-item
                  style="padding: 0.2rem 0.6rem 0.2rem 0.6rem;"
                >
                  <span style="margin-right: 0.6rem;">
                    {{ $t('FormRenting.RentingCode') }} »
                  </span>
                  <span style="font-size: 14px;">
                    <b-badge
                      pill
                      variant="light-info"
                      class="px-1 py-50"
                      style="font-size: 16px;"
                    >
                      {{ data.item.renting_code }}
                    </b-badge>
                  </span>
                </b-list-group-item>
              </b-list-group>
            </div>

            <!-- Product Type -->
            <div style="margin-top: 0.3rem;">
              <b-list-group>
                <!-- Product -->
                <b-list-group-item
                  style="padding: 0.4rem 0.6rem 0.2rem 0.6rem;"
                  class="top-bar-title-bg"
                >
                  <feather-icon
                    icon="AwardIcon"
                    class="mt-n25"
                  />
                  <span style="font-size: 14px; font-weight: bold;">
                    {{ $t('FormProduct.Product') }} »
                  </span>
                  <span
                    v-if="data.item.product_type"
                    style="font-size: 14px;"
                  >
                    <b-badge
                      pill
                      variant="light-info"
                      class="px-50 py-25"
                      style="font-size: 14px;"
                    >
                      {{ `${data.item.product_type.name} (${data.item.product_type.code})` }}
                    </b-badge>
                  </span>
                </b-list-group-item>
                <!-- Name -->
                <b-list-group-item
                  style="padding: 0.4rem 0.6rem 0.2rem 0.6rem;"
                >
                  <span
                    v-if="data.item.name"
                    style="font-size: 14px;"
                  >
                    {{ `${data.item.name}` }}
                  </span>
                </b-list-group-item>
                <!-- Category -->
                <b-list-group-item
                  style="padding: 0.4rem 0.6rem 0.2rem 0.6rem;"
                >
                  <span style="font-size: 14px;">
                    {{ $t('FormProduct.Category') }} »
                  </span>
                  <span style="font-size: 14px;">{{ data.item.category_name }}</span>
                </b-list-group-item>
                <!-- Department -->
                <b-list-group-item
                  style="padding: 0.4rem 0.6rem 0.2rem 0.6rem;"
                >
                  <span style="font-size: 14px;">
                    {{ $t('FormProduct.Department') }} »
                  </span>
                  <span style="font-size: 14px;">{{ data.item.department_name }}</span>
                </b-list-group-item>
              </b-list-group>
            </div>

            <div style="margin-top: 0.3rem;">
              <b-list-group>
                <b-list-group-item
                  style="padding: 0.2rem 0.6rem 0.2rem 0.6rem; background-color: #eeeeee;"
                >
                  <span style="margin-right: 0.6rem; font-weight: bold;">
                    {{ $t('FormRenting.RentingDate') }} »
                  </span>
                </b-list-group-item>
                <b-list-group-item
                  style="padding: 0.2rem 0.6rem 0.2rem 0.6rem;"
                >
                  <span style="font-size: 14px;">
                    <feather-icon icon="CalendarIcon" />
                    {{ `${data.item.start_date.name} - ${data.item.end_date.name}` }}
                  </span>
                </b-list-group-item>
              </b-list-group>
            </div>
          </template>

          <!-- Column: user -->
          <template #cell(user)="data">
            <!-- Renting User -->
            <div style="margin-top: 0.3rem;">
              <b-list-group>
                <b-list-group-item
                  style="padding: 0.2rem 0.6rem 0.2rem 0.6rem; background-color: #eeeeee;"
                >
                  <span style="margin-right: 0.6rem;">
                    {{ $t('FormRenting.RentingUser') }} »
                  </span>
                </b-list-group-item>
                <b-list-group-item
                  style="padding: 0.2rem 0.6rem 0.2rem 0.6rem;"
                >
                  <span style="font-size: 14px;">
                    <feather-icon icon="UserIcon" />
                    {{ data.item.user_name }}
                  </span>
                </b-list-group-item>
                <b-list-group-item
                  style="padding: 0.2rem 0.6rem 0.2rem 0.6rem;"
                >
                  <span style="font-size: 14px;">
                    <feather-icon icon="CalendarIcon" />
                    {{ data.item.create_date.name }}
                  </span>
                  <span
                    class="ml-75"
                    style="font-size: 14px;"
                  >
                    <feather-icon icon="ClockIcon" />
                    {{ data.item.create_time.name }}
                  </span>
                </b-list-group-item>
              </b-list-group>
            </div>
            <!-- Approve By -->
            <div style="margin-top: 0.3rem;">
              <b-list-group>
                <b-list-group-item
                  style="padding: 0.2rem 0.6rem 0.2rem 0.6rem; background-color: #eeeeee;"
                >
                  <span style="margin-right: 0.6rem;">
                    {{ $t('FormRenting.Approver') }} »
                  </span>
                </b-list-group-item>
                <b-list-group-item
                  style="padding: 0.2rem 0.6rem 0.2rem 0.6rem;"
                >
                  <span style="font-size: 14px;">
                    <feather-icon icon="UserIcon" />
                    {{ data.item.approver_user_name }}
                  </span>
                </b-list-group-item>
                <b-list-group-item
                  v-if="data.item.approve_date !== undefined"
                  style="padding: 0.2rem 0.6rem 0.2rem 0.6rem;"
                >
                  <span style="font-size: 14px;">
                    <feather-icon icon="CalendarIcon" />
                    {{ data.item.approve_date.name }}
                  </span>
                  <span
                    class="ml-75"
                    style="font-size: 14px;"
                  >
                    <feather-icon icon="ClockIcon" />
                    {{ data.item.approve_time.name }}
                  </span>
                </b-list-group-item>
                <b-list-group-item
                  style="padding: 0.2rem 0.6rem 0.2rem 0.6rem;"
                >
                  <span style="margin-right: 0.6rem;">
                    {{ $t('Status') }} »
                  </span>
                  <span style="font-size: 14px;">
                    <b-badge
                      pill
                      :variant="(skin === 'dark')? `light-${isStatusVariant(data.item.approve_status)}` : `light-${isStatusVariant(data.item.approve_status)}` "
                      class="text-capitalize"
                    >
                      {{ $t(`StatusMessage.${data.item.approve_status}`) }}
                    </b-badge>
                  </span>
                </b-list-group-item>
              </b-list-group>
            </div>
            <!-- Cancel By -->
            <div
              v-if="parseInt(data.item.cancel_user_id) > 0"
              style="margin-top: 0.3rem;"
            >
              <b-list-group>
                <b-list-group-item
                  style="padding: 0.2rem 0.6rem 0.2rem 0.6rem; background-color: #eeeeee;"
                >
                  <span style="margin-right: 0.6rem;">
                    {{ $t('FormRenting.Cancel By') }} »
                  </span>
                </b-list-group-item>
                <b-list-group-item
                  style="padding: 0.2rem 0.6rem 0.2rem 0.6rem;"
                >
                  <span style="font-size: 14px;">
                    <feather-icon icon="UserIcon" />
                    {{ data.item.cancel_by }}
                  </span>
                </b-list-group-item>
                <b-list-group-item
                  v-if="data.item.cancel_date !== undefined"
                  style="padding: 0.2rem 0.6rem 0.2rem 0.6rem;"
                >
                  <span style="font-size: 14px;">
                    <feather-icon icon="CalendarIcon" />
                    {{ data.item.cancel_date.name }}
                  </span>
                  <span
                    class="ml-75"
                    style="font-size: 14px;"
                  >
                    <feather-icon icon="ClockIcon" />
                    {{ data.item.cancel_time.name }}
                  </span>
                </b-list-group-item>
              </b-list-group>
            </div>
            <!-- Deleted By -->
            <div
              v-if="parseInt(data.item.delete_user_id) > 0"
              style="margin-top: 0.3rem;"
            >
              <b-list-group>
                <b-list-group-item
                  style="padding: 0.2rem 0.6rem 0.2rem 0.6rem; background-color: #eeeeee;"
                >
                  <span style="margin-right: 0.6rem;">
                    {{ $t('FormRenting.Deleted By') }} »
                  </span>
                </b-list-group-item>
                <b-list-group-item
                  style="padding: 0.2rem 0.6rem 0.2rem 0.6rem;"
                >
                  <span style="font-size: 14px;">
                    <feather-icon icon="UserIcon" />
                    {{ data.item.delete_by }}
                  </span>
                </b-list-group-item>
                <b-list-group-item
                  v-if="data.item.delete_date !== undefined"
                  style="padding: 0.2rem 0.6rem 0.2rem 0.6rem;"
                >
                  <span style="font-size: 14px;">
                    <feather-icon icon="CalendarIcon" />
                    {{ data.item.delete_date.name }}
                  </span>
                  <span
                    class="ml-75"
                    style="font-size: 14px;"
                  >
                    <feather-icon icon="ClockIcon" />
                    {{ data.item.delete_time.name }}
                  </span>
                </b-list-group-item>
              </b-list-group>
            </div>
            <!-- Payment By -->
            <div
              v-if="parseInt(data.item.payment_user_id) > 0"
              style="margin-top: 0.3rem;"
            >
              <b-list-group>
                <b-list-group-item
                  style="padding: 0.2rem 0.6rem 0.2rem 0.6rem; background-color: #eeeeee;"
                >
                  <span style="margin-right: 0.6rem;">
                    {{ $t('FormRenting.Payment By') }} »
                  </span>
                </b-list-group-item>
                <b-list-group-item
                  style="padding: 0.2rem 0.6rem 0.2rem 0.6rem;"
                >
                  <span style="font-size: 14px;">
                    <feather-icon icon="UserIcon" />
                    {{ data.item.payment_by }}
                  </span>
                </b-list-group-item>
                <b-list-group-item
                  v-if="data.item.payment_date !== undefined"
                  style="padding: 0.2rem 0.6rem 0.2rem 0.6rem;"
                >
                  <span style="font-size: 14px;">
                    <feather-icon icon="CalendarIcon" />
                    {{ data.item.payment_date.name }}
                  </span>
                  <span
                    class="ml-75"
                    style="font-size: 14px;"
                  >
                    <feather-icon icon="ClockIcon" />
                    {{ data.item.payment_time.name }}
                  </span>
                </b-list-group-item>
                <b-list-group-item
                  v-if="data.item.payment_amount_show !== undefined"
                  style="padding: 0.2rem 0.6rem 0.2rem 0.6rem;"
                >
                  <span style="margin-right: 0.6rem;">
                    {{ `${$t('FormRenting.PaymentAmount')} (${$t('FormRenting.Baht')})` }} »
                  </span>
                  <span style="font-size: 14px;">
                    <b-badge
                      pill
                      :variant="`light-${data.item.payment_status_color}`"
                      class="px-1 py-50"
                      style="font-size: 16px;"
                    >
                      {{ data.item.payment_amount_show }}
                    </b-badge>
                  </span>
                </b-list-group-item>
              </b-list-group>
            </div>
          </template>

          <!-- Column: Actions -->
          <template #cell(actions)="data">

            <div class="text-nowrap">
              <feather-icon
                v-if="$can('write', 'ModuleBooking.Renting')"
                :id="`renting-row-${data.item.id}-edit-icon`"
                icon="EditIcon"
                class="cursor-pointer mr-1"
                size="16"
                @click="editRentingForm(data)"
              />
              <b-tooltip
                v-if="$can('write', 'ModuleBooking.Renting')"
                :title="`${$t('Edit')} ${$t('TitleBooking.Renting')}`"
                :target="`renting-row-${data.item.id}-edit-icon`"
                :delay="{ show: 2500, hide: 50 }"
                placement="left"
              />

              <feather-icon
                v-if="$can('read', 'ModuleBooking.Renting')"
                :id="`renting-row-${data.item.id}-view-icon`"
                icon="EyeIcon"
                size="16"
                class="cursor-pointer mr-1"
                @click="viewRentingForm(data)"
              />
              <b-tooltip
                v-if="$can('read', 'ModuleBooking.Renting')"
                :title="`${$t('View')} ${$t('TitleBooking.Renting')}`"
                :target="`renting-row-${data.item.id}-view-icon`"
                :delay="{ show: 2500, hide: 50 }"
                placement="left"
              />

              <feather-icon
                v-if="$can('delete', 'ModuleBooking.Renting')"
                :id="`renting-row-${data.item.id}-delete-icon`"
                icon="TrashIcon"
                variant="light-success"
                size="16"
                class="cursor-pointer mr-1"
                @click="deleteRentingForm(data)"
              />
              <b-tooltip
                v-if="$can('delete', 'ModuleBooking.Renting')"
                :title="`${$t('Delete')} ${$t('TitleBooking.Renting')}`"
                :target="`renting-row-${data.item.id}-delete-icon`"
                :delay="{ show: 2500, hide: 50 }"
                placement="right"
              />

            </div>
          </template>

        </b-table>
        <div class="mx-2 mb-2">
          <b-row>

            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <span class="text-muted">
                {{ $t('Showing {from} to {to} of {of} entries',
                      {from: dataMeta.from, to: dataMeta.to, of: dataMeta.of })
                }}
              </span>
            </b-col>
            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >

              <b-pagination
                v-model="currentPage"
                :total-rows="totalRentings"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>

            </b-col>

          </b-row>
        </div>
      </b-card>

      <!-- Form -->
      <renting-form
        v-model="shallShowRentingFormModal"
        :renting-data="rentingData"
        :type-renting-form="typeRentingForm"
        @renting-form-update="updateRentingForm"
        @discard-renting-form="discardRentingForm"
      />

    </b-overlay>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput,
  BTable, BButton,
  BBadge, BPagination, BTooltip, BListGroup, BListGroupItem,
  BOverlay,
  BAvatar,
  // BImg,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {
  ref,
  onUnmounted,
} from '@vue/composition-api'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import { $themeConfig } from '@themeConfig'
import storeProductType from '@/views/master/product_type/storeProductType'
import storeProductCategory from '@/views/master/product_category/storeProductCategory'
import storeProduct from '@/views/master/product/storeProduct'
import moment from 'moment'
import tableRentingList from './tableRentingList'
import storeRenting from './storeRenting'
import RentingForm from './RentingForm.vue'
import RentingListFilters from './RentingListFilters.vue'

export default {
  components: {
    RentingListFilters,
    RentingForm,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BBadge,
    BPagination,
    BTooltip,
    BListGroup,
    BListGroupItem,
    BButton,
    BOverlay,
    BAvatar,
    // BImg,

    vSelect,
    FeatherIcon,
  },
  data() {
    return {
      showOverlay: false,
      rentingData: {},
      prNoData: {},
      dialog: false,
      skin: store.state.appConfig.layout.skin,
      selected: [],
      checkSelected: false,
      collapsedFilter: true,
      mediaLightBox: [{}],
    }
  },
  Computed: {
    checkSelected() {
      return !this.checkSelected
    },
  },
  mounted() {
    this.getDefaultRenting()
    this.locale = this.$i18n.locale
    this.getProductTypeOptions()
  },
  methods: {
    getDefaultRenting() {
      this.showOverlay = true
      store
        .dispatch('store-renting/getDefaultRenting', { language: this.$i18n.locale })
        .then(response => {
          const okMsg = this.$i18n.t('Process on successfully')
          this.$toast({
            component: ToastificationContent,
            props: {
              title: okMsg,
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          },
          {
            position: 'bottom-right',
          })
          this.showOverlay = false
          this.rentingData = response.data
        })
        .catch(error => {
          const { response, message } = error
          if (response) {
            const errorMsg = this.$i18n.t(response?.data.error)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: errorMsg,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            })
          } else if (message) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: error?.message,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            })
          }
          this.showOverlay = true
        })
    },
    addRentingForm() {
      this.showOverlay = true
      store
        .dispatch('store-renting/getDefaultRenting', { language: this.$i18n.locale })
        .then(response => {
          const okMsg = this.$i18n.t('Process on successfully')
          this.$toast({
            component: ToastificationContent,
            props: {
              title: okMsg,
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          },
          {
            position: 'bottom-right',
          })
          this.showOverlay = false
          this.rentingData = response.data
          this.typeRentingForm = 'Add'
          this.shallShowRentingFormModal = true
        })
        .catch(error => {
          const { response, message } = error
          if (response) {
            const errorMsg = this.$i18n.t(response?.data.error)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: errorMsg,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            })
          } else if (message) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: error?.message,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            })
          }
          this.showOverlay = false
        })
    },
    editRentingForm(data) {
      this.showOverlay = true
      store
        .dispatch('store-renting/getRenting', {
          language: this.$i18n.locale,
          id: data.item.id,
          lineOaId: data.item.line_oa_id,
        })
        .then(response => {
          const okMsg = this.$i18n.t('Process on successfully')
          this.$toast({
            component: ToastificationContent,
            props: {
              title: okMsg,
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          },
          {
            position: 'bottom-right',
          })
          this.showOverlay = false
          this.rentingData = response.data
          this.typeRentingForm = 'Edit'
          this.shallShowRentingFormModal = true
        })
        .catch(error => {
          const { response, message } = error
          if (response) {
            const errorMsg = this.$i18n.t(response?.data.error)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: errorMsg,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            })
          } else if (message) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: error?.message,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            })
          }
          this.showOverlay = false
        })
    },
    viewRentingForm(data) {
      this.showOverlay = true
      store
        .dispatch('store-renting/getRenting', { language: this.$i18n.locale, id: data.item.id })
        .then(response => {
          const okMsg = this.$i18n.t('Process on successfully')
          this.$toast({
            component: ToastificationContent,
            props: {
              title: okMsg,
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          },
          {
            position: 'bottom-right',
          })
          this.showOverlay = false
          this.rentingData = response.data
          this.typeRentingForm = 'View'
          this.shallShowRentingFormModal = true
        })
        .catch(error => {
          const { response, message } = error
          if (response) {
            const errorMsg = this.$i18n.t(response?.data.error)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: errorMsg,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            })
          } else if (message) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: error?.message,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            })
          }
          this.showOverlay = false
        })
    },
    deleteRentingForm(data) {
      this.$swal({
        title: this.$i18n.t('Are you sure?'),
        text: this.$i18n.t("You won't be able to revert this"),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$i18n.t('Yes, delete it'),
        cancelButtonText: this.$i18n.t('Cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value === true) {
          store.dispatch('store-renting/deleteRenting', { id: data.item.id })
            .then(() => {
              const okMsg = this.$i18n.t('Process on successfully')
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: okMsg,
                  icon: 'CheckCircleIcon',
                  variant: 'success',
                },
              },
              {
                position: 'bottom-right',
              })
              this.$swal({
                icon: 'success',
                title: this.$i18n.t('Deleted'),
                text: this.$i18n.t('Your data has been deleted'),
                timer: 3000,
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
              this.refetchData()
            })
            .catch(error => {
              const { response, message } = error
              if (response) {
                let errorMsg = this.$i18n.t(response?.data.error)
                if (errorMsg === '') {
                  errorMsg = this.$i18n.t(response?.data.message)
                }
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: errorMsg,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                },
                {
                  position: 'bottom-right',
                })
              } else if (message) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: error?.message,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                },
                {
                  position: 'bottom-right',
                })
              }
            })
        }
      })
    },
    updateRentingForm() {
      this.refetchData()
    },
    discardRentingForm() {
      // this.refetchData()
    },
    resetFilter() {
      this.rentingCodeFilter = ''
      this.nameFilter = ''
      this.statusFilter = ['all']
      this.paymentStatusFilter = ['all']

      this.productTypeFilter = '0'
      this.productCategoryFilter = '0'
      this.productFilter = '0'
      this.rentingDateFilter = []
      const lastMonth = moment().subtract('1', 'months')
      const monthStart = lastMonth.clone().startOf('month')
      const monthEnd = lastMonth.clone().endOf('month')
      const start = new Date(monthStart.format('YYYY-MM-DD'))
      const end = new Date(monthEnd.format('YYYY-MM-DD'))
      this.rentingDateFilter.push(start)
      this.rentingDateFilter.push(end)

      const filterList = JSON.parse(localStorage.getItem(`${$themeConfig.app.session}-filter-renting-list`))
      if (filterList !== null) {
        this.perPage = (filterList.perPage !== null) ? filterList.perPage : '5'
        this.currentPage = (filterList.page !== null) ? filterList.page : '1'
        this.sortBy = (filterList.sortBy !== null) ? filterList.sortBy : 'id'
        this.isSortDirDesc = (filterList.sortDesc !== null) ? filterList.sortDesc : 'DESC'
      }
    },
    onRentingReport() {
      this.$router.push({ name: 'report-renting' })
    },
  },
  setup() {
    const shallShowRentingFormModal = ref(false)
    const typeRentingForm = ref('')

    // Register module
    if (!store.hasModule('store-renting')) store.registerModule('store-renting', storeRenting)
    if (!store.hasModule('store-product-type')) store.registerModule('store-product-type', storeProductType)
    if (!store.hasModule('store-product-category')) store.registerModule('store-product-category', storeProductCategory)
    if (!store.hasModule('store-product')) store.registerModule('store-product', storeProduct)
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('store-renting')) store.unregisterModule('store-renting')
      if (store.hasModule('store-product-type')) store.unregisterModule('store-product-type')
      if (store.hasModule('store-product-category')) store.unregisterModule('store-product-category')
      if (store.hasModule('store-product')) store.unregisterModule('store-product')
    })

    const {
      getListRenting,
      tableColumns,
      perPage,
      currentPage,
      totalRentings,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      locale,
      refRentingListTable,
      refetchData,

      // UI
      documentTypeVariant,
      isStatusVariant,
      isStatusToText,
      textFirstUpper,
      numberFormat,

      rentingCodeFilter,
      nameFilter,
      statusFilter,
      paymentStatusFilter,

      statusOptions,
      paymentStatusOptions,

      productTypeFilter,
      productCategoryFilter,
      productFilter,
      rentingDateFilter,

      getProductTypeOptions,
      productTypeOptions,
      getProductCategoryOptions,
      productCategoryOptions,
      getProductOptions,
      productOptions,
    } = tableRentingList()

    const filterList = JSON.parse(localStorage.getItem(`${$themeConfig.app.session}-filter-renting-list`))
    if (filterList !== null) {
      searchQuery.value = (filterList.searchQuery !== null && filterList.searchQuery !== undefined) ? filterList.searchQuery : ''
      perPage.value = (filterList.perPage !== null && filterList.perPage !== undefined) ? filterList.perPage : ''
      currentPage.value = (filterList.page !== null && filterList.page !== undefined) ? filterList.page : ''
      sortBy.value = (filterList.sortBy !== null && filterList.sortBy !== undefined) ? filterList.sortBy : ''
      isSortDirDesc.value = (filterList.sortDesc !== null && filterList.sortDesc !== undefined) ? filterList.sortDesc : ''

      rentingCodeFilter.value = (filterList.rentingCode !== null && filterList.rentingCode !== undefined) ? filterList.rentingCode : ''
      nameFilter.value = (filterList.name !== null && filterList.name !== undefined) ? filterList.name : ''
      statusFilter.value = (filterList.status !== null && filterList.status !== undefined) ? filterList.status : []
      paymentStatusFilter.value = (filterList.paymentStatus !== null && filterList.paymentStatus !== undefined) ? filterList.paymentStatus : []

      if (filterList.rentingDate !== null && filterList.rentingDate !== undefined && Array.isArray(filterList.rentingDate)) {
        rentingDateFilter.value = filterList.rentingDate
      } else {
        rentingDateFilter.value = []
        // rentingDateFilter.value.push(moment().format('YYYY-MM-01'))
        // rentingDateFilter.value.push(moment().format('YYYY-MM-DD'))
      }
      productFilter.value = (filterList.product !== null && filterList.product !== undefined && filterList.product !== '') ? filterList.product : '0'
      productTypeFilter.value = (filterList.productType !== null && filterList.productType !== undefined && filterList.productType !== '') ? filterList.productType : '0'
      productCategoryFilter.value = (filterList.productCategory !== null && filterList.productCategory !== undefined && filterList.productCategory !== '') ? filterList.productCategory : '0'
    } else {
      rentingCodeFilter.value = ''
      nameFilter.value = ''
      statusFilter.value = ['all']
      paymentStatusFilter.value = ['all']

      productTypeFilter.value = '0'
      productCategoryFilter.value = '0'
      productFilter.value = '0'
      rentingDateFilter.value = []
      const lastMonth = moment().subtract('1', 'months')
      const monthStart = lastMonth.clone().startOf('month')
      const monthEnd = lastMonth.clone().endOf('month')
      const start = new Date(monthStart.format('YYYY-MM-DD'))
      const end = new Date(monthEnd.format('YYYY-MM-DD'))
      rentingDateFilter.value.push(start)
      rentingDateFilter.value.push(end)
    }

    return {
      shallShowRentingFormModal,
      typeRentingForm,

      getListRenting,
      tableColumns,
      perPage,
      currentPage,
      totalRentings,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      locale,
      refRentingListTable,
      refetchData,

      // UI
      documentTypeVariant,
      isStatusVariant,
      isStatusToText,
      textFirstUpper,
      numberFormat,

      rentingCodeFilter,
      nameFilter,
      statusFilter,
      paymentStatusFilter,

      statusOptions,
      paymentStatusOptions,

      productTypeFilter,
      productCategoryFilter,
      productFilter,
      rentingDateFilter,

      getProductTypeOptions,
      productTypeOptions,
      getProductCategoryOptions,
      productCategoryOptions,
      getProductOptions,
      productOptions,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.renting-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss">
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';
@import '~@core/scss/base/components/variables';

.dark-layout {
  div{
    .b-overlay {
      .bg-light {
        background-color: $theme-dark-body-bg !important;
      }
    }
    .swal2-popup {
      background-color: $theme-dark-body-bg !important;
    }
  }
  .table.b-table > tbody .b-table-row-selected.table-active td {
    background-color: $theme-dark-table-active-bg;
  }
  .table.b-table.table-hover > tbody > tr.table-active:hover td,
  .table.b-table.table-hover > tbody > tr.table-active:hover th {
    background-image: linear-gradient($theme-dark-table-active-hover-bg, $theme-dark-table-active-hover-bg);
  }
  .top-bar-title-bg{
    background-color: $theme-dark-modal-header-bg !important;
  }
}
.top-bar-title-bg{
  background-color: $theme-light-modal-header-bg !important;
}
.b-table-selectable{
  .feather{
    font-size: 1.3rem;
  }
}
</style>
